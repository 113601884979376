import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import '../../Auth/pages/SignupLoginPage';

const UserForm = ({
  onClose,
  onSave,
  initialData = {},
  userData,
  isEditMode
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

useEffect(() => {
  if (initialData) {
    Object.keys(initialData).forEach((key) => {
      if (key === "password" && initialData[key]) {
        setValue(key, initialData[key].substring(0, 10));
      } else {
        setValue(key, initialData[key]);
      }
    });
  }
}, [initialData, setValue]);

  const onSubmit = (data) => {
    const payload = {
      orgId: userData.orgId || 0,
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber1: data.phoneNumber1,
      isAdmin: false,
      userName: data.userName,
      ...(isEditMode && { userId: initialData.userId })
    };
      if (!isEditMode) {
        payload.email = data.email;
        payload.password = data.password;
      }
    onSave(payload);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 mt-4">
      <div className="signup-form-grid">
        <div className="form-field">
          <label htmlFor="firstName" className="block text-gray-700">
            First Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="firstName"
            className="w-full border border-gray-300 rounded-md"
            {...register("firstName", {
              required: "First name is required",
              pattern: {
                value: /^[A-Za-z\s]+$/,
                message: "First name can only contain alphabets",
              },
              maxLength: {
                value: 20,
                message: "First name cannot exceed 20 characters",
              },
            })}
          />
          <p className="error">{errors.firstName?.message}</p>
        </div>
        <div className="form-field">
          <label htmlFor="lastName" className="block text-gray-700">
            Last Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="lastName"
            className="w-full border border-gray-300 rounded-md"
            {...register("lastName", {
              required: "Last name is required",
              pattern: {
                value: /^[A-Za-z\s]+$/,
                message: "Last name can only contain alphabets",
              },
              maxLength: {
                value: 20,
                message: "First name cannot exceed 20 characters",
              },
            })}
          />
          <p className="error">{errors.lastName?.message}</p>
        </div>
        <div className="form-field">
          <label htmlFor="email" className="block text-gray-700">
            Email <span className="text-red-500">*</span>
          </label>
          <input
            type="email"
            id="email"
            className="w-full border border-gray-300 rounded-md"
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                message: "Invalid email format",
              },
            })}
            disabled={isEditMode}
          />
          <p className="error">{errors.email?.message}</p>
        </div>
        <div className="form-field">
          <label htmlFor="password" className="block text-gray-700">
            Password <span className="text-red-500">*</span>
          </label>
          <input
            type="password"
            id="password"
            className="w-full border border-gray-300 rounded-md"
            {...register("password", {
              required: "Password is required",
              maxLength: {
                value: 20,
                message: "Password cannot exceed 20 characters",
              },
            })}
            disabled={isEditMode}
          />
          <p className="error">{errors.password?.message}</p>
        </div>
        <div className="form-field full-width">
          <label htmlFor="phoneNumber1" className="block text-gray-700">
            Contact Number <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="phoneNumber1"
            className="w-full border border-gray-300 rounded-md"
            {...register("phoneNumber1", {
              required: "Phone number is required",
              pattern: {
                value: /^\d{10}$/,
                message: "Phone number must be 10 digits",
              },
              maxLength: {
                value: 10,
                message: "Phone number cannot exceed 10 digits",
              },
            })}
          />
          <p className="error">{errors.phoneNumber1?.message}</p>
        </div>
      </div>
      <div className="flex justify-center">
        <button
          type="button"
          onClick={onClose}
          className="text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
        >
          Close
        </button>
        <button
          type="submit"
          className="ml-5 text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
        >
          Save
        </button>
      </div>
    </form>
  );
};

export default UserForm;
