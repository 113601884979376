// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.error {
    color: red;
    font-size: 12px;
    text-align: left;
}

.form-group {
    display: flex;
    gap: 1rem;
  }
  
  .form-group .form-field {
    flex: 1 1;
  }
  
  .signup-button {
    width: 100%;
    padding: 0.75rem;
    /* background: linear-gradient(to right, #92bcd8, #3e2ab8); */
    background: linear-gradient(to right, #d692d8, #417a87);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  label {
    font-size: 15px;
  }
  .f-right {
    float: right;
  }

.gradient-button {
  width: 30%;
  background: linear-gradient(to right, #6a11cb, #2575fc);
  color: white;
  font-weight: bold;
  padding: 10px;
  margin-top: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 16px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.gradient-button:hover {
  background: linear-gradient(to right, #5a0eb5, #1e65c1);
}

input {
  padding: 5px !important;
}

.contact-field {
  width: 100%;
  margin-bottom: 1rem;
}

.contact-field input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
  

  
  
  
  
  `, "",{"version":3,"sources":["webpack://./src/Auth/components/SignupForm.css"],"names":[],"mappings":";AACA;IACI,UAAU;IACV,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,SAAS;EACX;;EAEA;IACE,SAAO;EACT;;EAEA;IACE,WAAW;IACX,gBAAgB;IAChB,6DAA6D;IAC7D,uDAAuD;IACvD,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,iCAAiC;EACnC;;EAEA;IACE,eAAe;EACjB;EACA;IACE,YAAY;EACd;;AAEF;EACE,UAAU;EACV,uDAAuD;EACvD,YAAY;EACZ,iBAAiB;EACjB,aAAa;EACb,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,sCAAsC;EACtC,eAAe;EACf,cAAc;EACd,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,uDAAuD;AACzD;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,kBAAkB;AACpB","sourcesContent":["\n.error {\n    color: red;\n    font-size: 12px;\n    text-align: left;\n}\n\n.form-group {\n    display: flex;\n    gap: 1rem;\n  }\n  \n  .form-group .form-field {\n    flex: 1;\n  }\n  \n  .signup-button {\n    width: 100%;\n    padding: 0.75rem;\n    /* background: linear-gradient(to right, #92bcd8, #3e2ab8); */\n    background: linear-gradient(to right, #d692d8, #417a87);\n    color: white;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    transition: background-color 0.3s;\n  }\n\n  label {\n    font-size: 15px;\n  }\n  .f-right {\n    float: right;\n  }\n\n.gradient-button {\n  width: 30%;\n  background: linear-gradient(to right, #6a11cb, #2575fc);\n  color: white;\n  font-weight: bold;\n  padding: 10px;\n  margin-top: 10px;\n  border: none;\n  border-radius: 4px;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n  font-size: 16px;\n  display: block;\n  margin-left: auto;\n  margin-right: auto;\n}\n\n.gradient-button:hover {\n  background: linear-gradient(to right, #5a0eb5, #1e65c1);\n}\n\ninput {\n  padding: 5px !important;\n}\n\n.contact-field {\n  width: 100%;\n  margin-bottom: 1rem;\n}\n\n.contact-field input {\n  width: 100%;\n  padding: 10px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n}\n  \n\n  \n  \n  \n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
