import React, { useEffect, useState } from "react";
import { useForm, Controller, useWatch } from "react-hook-form";
import { toast } from "react-toastify";
import Select from "react-select";
import { useClients } from "../../contexts/ClientsContext";
import { useJobs } from "../../contexts/JobsContext";
import { getSkills, getCities } from "../../Auth/services/Authapi";
import { noticePeriodOptions } from "../../utils/Utility";

const CandidateForm = ({
  onClose,
  onSave,
  initialData = {},
  isEditMode = false,
  userData,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const clients = useClients();
  const jobs = useJobs();
  const [skills, setSkills] = useState([]);
  const [cities, setCities] = useState([]);

  // Watch the selected client
  const selectedClientId = useWatch({
    control,
    name: "clientId",
    defaultValue: initialData?.clientId || "",
  });

  const citiesOptions = cities.map((city) => ({
    value: city.locationId,
    label: city.locationName,
  }));

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const skillsData = await getSkills();
        setSkills(
          Array.isArray(skillsData.data.data) ? skillsData.data.data : []
        );
        const citiesData = await getCities();
        setCities(
          Array.isArray(citiesData.data.data) ? citiesData.data.data : []
        );
      } catch (error) {
        toast.error("An error occurred while fetching initial data");
      }
    };

    fetchInitialData();
  }, []);

  useEffect(() => {
    console.log(initialData);
    if (initialData && Object.keys(initialData).length > 0) {
      Object.keys(initialData).forEach((key) => {
        if (key !== "resume") {
          if (key === "clientId") {
            setValue("clientId", {
              value: initialData.clientId,
              label: initialData.clientName,
            });
          } else if (key === "jobId") {
            setValue("jobId", {
              value: initialData.jobId,
              label: initialData.jobTitle,
            });
          } else if (key === "locationId") {
            const matchedCity = cities.find(
              (city) => city.locationId === initialData.locationId
            );
            if (matchedCity) {
              setValue("location", {
                value: matchedCity.locationId,
                label: matchedCity.locationName,
              });
            }
          } else if (
            key === "candidateSkills" &&
            Array.isArray(initialData.candidateSkills)
          ) {
            const mappedSkills = initialData.candidateSkills.map((skill) => {
              const matchedSkill = skills.find(
                (s) => s.skillId === skill.skillId
              );
              return {
                value: skill.skillId,
                label: matchedSkill
                  ? matchedSkill.skillName
                  : `Skill ${skill.skillId}`,
              };
            });
            setValue("candidateSkills", mappedSkills);
          } else if (key === "noticePeriod") {
            setValue("noticePeriod", initialData.noticePeriod.toString());
          } else {
            setValue(key, initialData[key]);
          }
        }
      });
    }
  }, [initialData, skills, setValue]);

  const skillsOptions = skills.map((skill) => ({
    value: skill.skillId,
    label: skill.skillName,
  }));

  const getFilteredOptions = (selectedSkills) => {
    return skillsOptions.filter(
      (option) =>
        !selectedSkills.some((selected) => selected.value === option.value)
    );
  };

  const onSubmit = (data) => {
    const payload = {
      clientId: data.clientId.value,
      jobId: data.jobId.value,
      candidateFullName: data.candidateFullName,
      emailId: data.emailId,
      mobileNo: Number(data.mobileNo),
      totalExperience: Number(data.totalExperience),
      candidateSkills: data.candidateSkills,
      noticePeriod: Number(data.noticePeriod),
      currentCtc: Number(data.currentCtc),
      expectedCtc: Number(data.expectedCtc),
      currentCompany: data.currentCompany,
      locationId: Number(data.location.value),
      teamLeadExperience: Number(data.teamLeadExperience),
      reasonForChange: data.reasonForChange,
      status: 'open'
    };
    if (initialData && initialData.candidateId) {
      payload.candidateId = initialData.candidateId;
    }
    if (data.resume) {
      console.log(data, "dataaaaaaaa");
    }
    onSave(payload, data.resume[0]);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
      <div className="grid grid-cols-2 gap-4">
        <div className="form-field">
          <label>
            Select Employer/Client <span className="text-red-500">*</span>
          </label>
          <div className="ml-3">
            <Controller
              name="clientId"
              control={control}
              rules={{ required: "Client is required" }}
              render={({ field }) => (
                <Select
                  {...field}
                  options={clients.map((client) => ({
                    value: client.clientId,
                    label: client.clientName,
                  }))}
                />
              )}
            />
            <p className="text-red-600">{errors.clientId?.message}</p>
          </div>
        </div>

        <div className="form-field">
          <label>
            Select Job <span className="text-red-500">*</span>
          </label>
          <div className="ml-3">
            <Controller
              name="jobId"
              control={control}
              rules={{ required: "Job is required" }}
              render={({ field }) => (
                <Select
                  {...field}
                  options={jobs.map((job) => ({
                    value: job.jobId,
                    label: job.jobTitle,
                  }))}
                />
              )}
            />
            <p className="text-red-600">{errors.jobId?.message}</p>
          </div>
        </div>

        <div className="form-field">
          <label>
            Full Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            {...register("candidateFullName", {
              required: "Full name is required",
            })}
            className="border p-2 w-full"
          />
          <p className="text-red-600">{errors.candidateFullName?.message}</p>
        </div>

        <div className="form-field">
          <label>
            Email <span className="text-red-500">*</span>
          </label>
          <input
            type="email"
            {...register("emailId", {
              required: "Email is required",
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                message: "Invalid email format",
              },
            })}
            className="border p-2 w-full"
          />
          <p className="text-red-600">{errors.email?.message}</p>
        </div>

        <div className="form-field">
          <label>
            Mobile <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            {...register("mobileNo", {
              required: "Mobile number is required",
              pattern: {
                value: /^\d{10}$/,
                message: "Mobile number must be 10 digits",
              },
              maxLength: {
                value: 10,
                message: "Mobile number cannot exceed 10 digits",
              },
            })}
            className="border p-2 w-full"
          />
          <p className="text-red-600">{errors.mobileNo?.message}</p>
        </div>

        <div className="form-field">
          <label>
            Total Experience <span className="text-red-500">*</span>
          </label>
          <input
            type="number"
            {...register("totalExperience", {
              required: "Total experience is required",
            })}
            className="border p-2 w-full"
          />
          <p className="text-red-600">{errors.totalExperience?.message}</p>
        </div>
        <div>
          <label>
            Required Skills <span className="text-red-500">*</span>
          </label>
          <Controller
            name="candidateSkills"
            control={control}
            rules={{ required: "Required skills are required" }}
            render={({ field }) => (
              <Select
                {...field}
                options={getFilteredOptions(field.value || [])}
                isMulti
                className="basic-multi-select ml-3"
                classNamePrefix="select"
                value={
                  field.value
                    ? field.value.map((selectedSkill) => ({
                        value: selectedSkill.skillId || selectedSkill.value,
                        label: selectedSkill.skillName || selectedSkill.label,
                      }))
                    : []
                }
                onChange={(selectedOptions) =>
                  field.onChange(
                    selectedOptions.map((option) => ({
                      skillId: option.value,
                      skillName: option.label,
                      orgId: userData.orgId,
                      clientId: initialData?.clientId,
                      jobId: initialData?.jobId,
                    }))
                  )
                }
              />
            )}
          />

          {errors.candidateSkills && (
            <p className="text-red-600">{errors.candidateSkills.message}</p>
          )}
        </div>
        <div className="form-field">
          <label>
            Notice Period<span className="text-red-500">*</span>
          </label>
          <select
            {...register("noticePeriod", {
              required: "Notice period is required",
            })}
            className="border p-2 w-full"
          >
            <option value="">Please Select</option>
            {noticePeriodOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {errors.noticePeriod && (
            <p className="text-red-600">{errors.noticePeriod.message}</p>
          )}
        </div>
        <div className="form-field">
          <label>
            Current CTC (In Lakhs) <span className="text-red-500">*</span>
          </label>
          <input
            type="number"
            {...register("currentCtc", { required: "Current CTC is required" })}
            className="border p-2 w-full"
          />
          <p className="text-red-600">{errors.currentCtc?.message}</p>
        </div>

        <div className="form-field">
          <label>
            Expected CTC (In Lakhs) <span className="text-red-500">*</span>
          </label>
          <input
            type="number"
            {...register("expectedCtc", {
              required: "Expected CTC is required",
            })}
            className="border p-2 w-full"
          />
          <p className="text-red-600">{errors.expectedCtc?.message}</p>
        </div>

        <div className="form-field">
          <label>
            Current Company <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            {...register("currentCompany")}
            className="border p-2 w-full"
          />
        </div>

        <div className="form-field">
          <label>
            Current Location <span className="text-red-500">*</span>
          </label>
          <div className="ml-3"></div>
          <Controller
            name="location"
            control={control}
            rules={{ required: "Location is required" }}
            render={({ field }) => (
              <Select
                {...field}
                options={citiesOptions}
                className="basic-select ml-3"
                classNamePrefix="select"
              />
            )}
          />
          {errors.location && (
            <p className="text-red-600">{errors.location.message}</p>
          )}
        </div>

        <div className="form-field">
          <label>
            Team Lead Experience <span className="text-red-500">*</span>
          </label>
          <input
            type="number"
            {...register("teamLeadExperience")}
            className="border p-2 w-full"
          />
        </div>

        <div className="form-field">
          <label>Upload CV</label>
          <input
            type="file"
            {...register("resume")}
            accept=".pdf,.doc,.docx"
            className="border p-2 w-full"
          />
          <p className="text-red-600">{errors.resume?.message}</p>
        </div>

        <div className="col-span-1 md:col-span-2 ml-3">
          <label>Reason for Change</label>
          <textarea
            {...register("reasonForChange")}
            className="border p-2 w-full"
          />
        </div>
      </div>
      <div className="flex justify-center">
        <button
          type="button"
          onClick={onClose}
          className="text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
        >
          Close
        </button>
        <button
          type="submit"
          className="ml-5 text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
        >
          Save
        </button>
      </div>
    </form>
  );
};

export default CandidateForm;
