import React, { createContext, useContext, useState, useEffect } from "react";
import { getClients } from "../Auth/services/Authapi";
import { useSelector } from "react-redux";

const ClientsContext = createContext();

export const useClients = () => useContext(ClientsContext);

export const ClientsProvider = ({ children }) => {
  const [clients, setClients] = useState([]);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        if (user && user.orgId) {
          const clientsData = await getClients(user.orgId);
          setClients(
            Array.isArray(clientsData.data.data) ? clientsData.data.data : []
          );
        }
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    };

    fetchClients();
  }, [user]);

  return (
    <ClientsContext.Provider value={clients}>
      {children}
    </ClientsContext.Provider>
  );
};
