// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gradient-button {
  width: 30%;
  background: linear-gradient(to right, #6a11cb, #2575fc);
  color: white;
  font-weight: bold;
  padding: 10px;
  margin-top: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 16px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.gradient-button:hover {
  background: linear-gradient(to right, #5a0eb5, #1e65c1);
}

input {
  padding: 5px !important;
}

`, "",{"version":3,"sources":["webpack://./src/Auth/components/LoginForm.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,uDAAuD;EACvD,YAAY;EACZ,iBAAiB;EACjB,aAAa;EACb,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,sCAAsC;EACtC,eAAe;EACf,cAAc;EACd,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,uDAAuD;AACzD;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".gradient-button {\n  width: 30%;\n  background: linear-gradient(to right, #6a11cb, #2575fc);\n  color: white;\n  font-weight: bold;\n  padding: 10px;\n  margin-top: 10px;\n  border: none;\n  border-radius: 4px;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n  font-size: 16px;\n  display: block;\n  margin-left: auto;\n  margin-right: auto;\n}\n\n.gradient-button:hover {\n  background: linear-gradient(to right, #5a0eb5, #1e65c1);\n}\n\ninput {\n  padding: 5px !important;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
