import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { UserLogin, UserSignup } from "../Auth/services/Authapi";

export const LoginThunk = createAsyncThunk(
  "ats-service/api/v1/auth",
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await UserLogin(credentials);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const SignupThunk = createAsyncThunk(
  "ats-service/api/v1/signUp",
  async (userInfo, { rejectWithValue }) => {
    try {
      const response = await UserSignup(userInfo);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialUser = JSON.parse(localStorage.getItem("user"));

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: initialUser,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(LoginThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(LoginThunk.fulfilled, (state, action) => {
        state.user = action?.payload?.data;
        localStorage.setItem("user", JSON.stringify(state.user));
        state.loading = false;
      })
      .addCase(LoginThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(SignupThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(SignupThunk.fulfilled, (state, action) => {
        state.user = action?.payload?.data;
        state.loading = false;
      })
      .addCase(SignupThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      });
  },
});

export default authSlice.reducer;
