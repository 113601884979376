import React, { useState, useEffect } from "react";
import Modal from "../../components/Modal";
import CandidateForm from "./CandidateForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faUserPlus,
  faShareSquare,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import {
  getCandidates,
  addCandidate,
  updateCandidate,
  deleteCandidate,
  uploadResume,
  updateCandidateStatus,
  downloadFile
} from "../../Auth/services/Authapi";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import ConfirmationModal from "../../../src/components/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import { useClients } from "../../contexts/ClientsContext";
import { useJobs } from "../../contexts/JobsContext";
import { FaEye, FaDownload, FaInfoCircle } from "react-icons/fa"; 

const ManageCandidates = () => {
  const [candidates, setCandidates] = useState([]);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [viewCandidate, setViewCandidate] = useState(null);
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedJob, setSelectedJob] = useState("");
  const [isAllSelected, setIsAllSelected] = useState(false);

  const user = useSelector((state) => state.auth.user);
  const clients = useClients();
  const jobs = useJobs();
  const navigate = useNavigate();

  useEffect(() => {
    fetchCandidates();
  }, [selectedClient, selectedJob]);

  const fetchCandidates = async () => {
    try {
      const payload = { orgId: user.orgId };
      if (selectedClient) {
        payload.clientId = selectedClient;
      }
      if (selectedJob) {
        payload.jobId = selectedJob;
      }
      const candidateData = await getCandidates(payload);
      setCandidates(
        Array.isArray(candidateData.data.data) ? candidateData.data.data : []
      );
    } catch (error) {
      toast.error("An error occurred while fetching the candidate data");
    }
  };

  const addNewCandidate = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedCandidate(null);
  };

  const openDeleteConfirmation = (candidateId) => {
    console.log('delete confirm is clicked');
    setSelectedCandidate(candidateId);
    setIsDeleteModalOpen(true);
  };

  const handleSaveCandidate = async (candidate, resume) => {
    try {
      let response;
      if (candidate.candidateId) {
        response = await updateCandidate(candidate);
        toast.success("Candidate updated successfully");
      } else {
        candidate.orgId = user.orgId;
        response = await addCandidate(candidate);
        toast.success("Candidate added successfully");
      }

      const { jobId, orgId, clientId, candidateId } = response.data.data;

      if (resume) {
        const formData = new FormData();
        formData.append("file", resume);
        formData.append("orgId", orgId);
        formData.append("jobId", jobId);
        formData.append("clientId", clientId);
        formData.append("candidateId", candidateId);
        await uploadResume(formData);
        toast.success("Resume uploaded successfully");
      }
    } catch (error) {
      toast.error("An error occurred while saving the candidate data");
    }
    fetchCandidates();
    handleCloseModal();
  };
  const handleDownloadFile = async (jdUrl, type) => {
    try {
      const response = await downloadFile(jdUrl);

      const contentDisposition = response.headers["content-disposition"];
      const contentType =
        response.headers["content-type"] || "application/octet-stream";

      let fileName = "downloaded_file";

      if (contentDisposition && contentDisposition.includes("filename")) {
        const matches = /filename="([^"]+)"/.exec(contentDisposition);
        if (matches != null && matches[1]) {
          fileName = matches[1];
        }
      } else {
        if (contentType === "application/pdf") {
          fileName = "downloaded_file.pdf";
        } else if (contentType === "application/msword") {
          fileName = "downloaded_file.doc";
        } else if (
          contentType ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          fileName = "downloaded_file.docx";
        } else {
          fileName = "downloaded_file.bin";
        }
      }

      const fileBlob = new Blob([response.data], { type: contentType });

      if (
        type === "download" ||
        contentType === "application/msword" ||
        contentType ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(fileBlob);
        link.download = fileName;
        link.click();
      } else if (type === "view" && contentType === "application/pdf") {
        const fileURL = window.URL.createObjectURL(fileBlob);
        window.open(fileURL);
      }
    } catch (error) {
      toast.error("An error occurred while downloading the file");
    }
  };

  const handleEdit = (candidate) => {
    setIsModalOpen(true);
    setSelectedCandidate(candidate);
  };

  const handleDelete = async (candidateId) => {
    try {
      await deleteCandidate(candidateId);
      const updatedCandidates = candidates.filter(
        (candidate) => candidate.candidateId !== candidateId
      );
      setCandidates(updatedCandidates);
      toast.success("Candidate deleted successfully");
    } catch (error) {
      toast.error("An error occurred while deleting the candidate");
    }
  };

  const toggleCandidateSelection = (candidateId) => {
    setSelectedCandidates((prevSelected) =>
      prevSelected.includes(candidateId)
        ? prevSelected.filter((id) => id !== candidateId)
        : [...prevSelected, candidateId]
    );
  };

  const handleView = (candidate) => {
    setViewCandidate(candidate);
  };

  const closeViewModal = () => {
    setViewCandidate(null);
  };

  const toggleSelectAll = (e) => {
    const checked = e.target.checked;
    setIsAllSelected(checked);

    if (checked) {
      setSelectedCandidates(
        candidates.map((candidate) => candidate.candidateId)
      );
    } else {
      setSelectedCandidates([]);
    }
  };

  const handleShareCandidates = async () => {
    if (!selectedClient) {
      toast.error("Please select a client before sharing candidates.");
      return;
    }

    if (!selectedJob) {
      toast.error("Please select a job before sharing candidates.");
      return;
    }

    if (selectedCandidates.length > 0) {
      const payload = selectedCandidates.map((candidateId) => ({
        orgId: user.orgId,
        candidateId,
        clientId: Number(selectedClient),
        jobId: Number(selectedJob),
        status: "Shared to Client",
      }));

      try {
        const response = await updateCandidateStatus(payload);
        if (response.data.statusCode === 200) {
          toast.success("Candidates shared successfully");
          setSelectedClient("");
          setSelectedJob("");
          setSelectedCandidates([]);
          setIsAllSelected(false);
        } else {
          toast.error("An error occurred while sharing the candidates");
        }
      } catch (error) {
        toast.error("An error occurred while sharing the candidates");
      }
    } else {
      toast.error("Please select at least one candidate to share.");
    }
  };

  return (
    <div className="m-2">
      <div className="flex justify-between items-center mb-4">
        <div className="font-bold text-xl ml-3">All Candidates</div>
      </div>

      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center space-x-4">
          <select
            value={selectedClient}
            onChange={(e) => setSelectedClient(e.target.value)}
            className="w-40 p-2 border rounded-md"
          >
            <option value="">Select Client</option>
            {clients.map((client) => (
              <option key={client.clientId} value={client.clientId}>
                {client.clientName}
              </option>
            ))}
          </select>

          <select
            value={selectedJob}
            onChange={(e) => setSelectedJob(e.target.value)}
            className="w-40 p-2 border rounded-md"
            disabled={!selectedClient}
          >
            <option value="">Select Job</option>
            {jobs
              .filter((job) => Number(job.clientId) === Number(selectedClient))
              .map((job) => (
                <option key={job.jobId} value={job.jobId}>
                  {job.jobTitle}
                </option>
              ))}
          </select>
        </div>

        <div className="flex space-x-2">
          <button
            onClick={addNewCandidate}
            className="px-3 py-2 bg-purple-600 hover:bg-purple-500 text-white rounded-md text-sm"
          >
            <FontAwesomeIcon icon={faUserPlus} className="mr-2" />
            Add Candidate
          </button>

          {selectedCandidates.length > 0 && (
            <button
              onClick={handleShareCandidates}
              className="px-3 py-2 bg-green-600 hover:bg-green-500 text-white rounded-md text-sm"
            >
              <FontAwesomeIcon icon={faShareSquare} className="mr-2" />
              Share
            </button>
          )}
        </div>
      </div>

      <table className="table-auto w-full border-collapse mt-5">
        <thead>
          <tr className="table-header-bg">
            <th className="px-4 py-2">
              <input
                type="checkbox"
                checked={isAllSelected}
                onChange={toggleSelectAll}
              />
            </th>
            <th>S No</th>
            <th className="px-4 py-2">Name</th>
            <th className="px-4 py-2">Email</th>
            <th className="px-4 py-2">Phone</th>
            <th className="px-4 py-2">Notice Period</th>
            <th className="px-4 py-2">Current CTC</th>
            <th className="px-4 py-2">Resume</th>
            <th className="px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {candidates.length === 0 ? (
            <tr>
              <td colSpan="8" className="text-center py-2 no-records">
                No candidates are available
              </td>
            </tr>
          ) : (
            candidates.map((candidate, index) => (
              <tr key={candidate.candidateId} className="border-b">
                <td className="px-4 py-2 text-center">
                  <input
                    type="checkbox"
                    checked={selectedCandidates.includes(candidate.candidateId)}
                    onChange={() =>
                      toggleCandidateSelection(candidate.candidateId)
                    }
                  />
                </td>
                <td>{index + 1}</td>
                <td className="px-4 py-2 text-center">
                  {candidate.candidateFullName}
                </td>
                <td className="px-4 py-2 text-center">{candidate.emailId}</td>
                <td className="px-4 py-2 text-center">{candidate.mobileNo}</td>
                <td className="px-4 py-2 text-center">
                  {candidate.noticePeriod}
                </td>
                <td className="px-4 py-2 text-center">
                  {candidate.currentCtc}
                </td>
                <td className="text-center py-3 px-4">
                  <div className="flex">
                    <FaDownload
                      style={{
                        cursor: "pointer",
                        color: "#00bfff",
                        marginRight: "10px",
                      }}
                      onClick={() =>
                        handleDownloadFile(candidate.resumeUrl, "download")
                      }
                    />
                    <FaEye
                      style={{ cursor: "pointer", color: "#4CAF50" }}
                      onClick={() =>
                        handleDownloadFile(candidate.resumeUrl, "view")
                      }
                    />
                  </div>
                </td>
                <td className="px-4 py-2 text-center flex justify-center space-x-2">
                  <FaInfoCircle
                    style={{ cursor: "pointer", color: "#4CAF50" }}
                    onClick={() => handleView(candidate)}
                  />
                  {/* <FontAwesomeIcon
                    icon={faEye}
                    style={{
                      cursor: "pointer",
                      color: "#4CAF50",
                    }}
                    onClick={() => handleView(candidate)}
                  /> */}
                  <FontAwesomeIcon
                    icon={faEdit}
                    style={{
                      cursor: "pointer",
                      color: "#00bfff",
                    }}
                    onClick={() => handleEdit(candidate)}
                  />
                  <FontAwesomeIcon
                    icon={faTrash}
                    style={{
                      cursor: "pointer",
                      color: "red",
                    }}
                    onClick={() =>
                      openDeleteConfirmation(candidate.candidateId)
                    }
                  />
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>

      {/* Candidate Details Modal */}
      {viewCandidate && (
        <Modal
          isOpen={!!viewCandidate}
          onRequestClose={closeViewModal}
          onClose={() => setViewCandidate(false)}
          header={<h2 className="text-xl font-bold mb-4">Candidate Details</h2>}
        >
          <div className="p-4 bg-white rounded-lg shadow-lg space-y-2">
            <p>
              <span className="font-semibold">Name:</span>{" "}
              {viewCandidate.candidateFullName}
            </p>
            <p>
              <span className="font-semibold">Client Name:</span>{" "}
              {viewCandidate.clientName}
            </p>
            <p>
              <span className="font-semibold">Current Company:</span>{" "}
              {viewCandidate.currentCompany}
            </p>
            <p>
              <span className="font-semibold">Email:</span>{" "}
              {viewCandidate.emailId}
            </p>
            <p>
              <span className="font-semibold">Phone:</span>{" "}
              {viewCandidate.mobileNo}
            </p>
            <p>
              <span className="font-semibold">Notice Period:</span>{" "}
              {viewCandidate.noticePeriod}
            </p>
            <p>
              <span className="font-semibold">Current CTC:</span>{" "}
              {viewCandidate.currentCtc}
            </p>
            <p>
              <span className="font-semibold">Expected CTC:</span>{" "}
              {viewCandidate.expectedCtc}
            </p>
            <p>
              <span className="font-semibold">Status:</span>{" "}
              {viewCandidate.status}
            </p>
            <p>
              <span className="font-semibold">Resume:</span>{" "}
              <a
                href={viewCandidate.resumeUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500"
              >
                View Resume
              </a>
            </p>
          </div>
          <div className="mt-6 flex justify-end">
            <button
              onClick={closeViewModal}
              className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-300"
            >
              Close
            </button>
          </div>
        </Modal>
      )}

      <Modal
        isOpen={isModalOpen}
        header={
          <h1>{selectedCandidate ? "Update Candidate" : "Add Candidate"}</h1>
        }
        onRequestClose={handleCloseModal}
        onClose={() => setIsModalOpen(false)}
      >
        <CandidateForm
          onClose={handleCloseModal}
          onSave={handleSaveCandidate}
          initialData={selectedCandidate}
          userData={user}
          isEditMode={!!selectedCandidate}
        />
      </Modal>

      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={() => handleDelete(selectedCandidate)}
        message={`Are you sure you want to delete this candidate?`}
      />
    </div>
  );
};

export default ManageCandidates;
