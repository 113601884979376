import React, { useState, useEffect } from "react";
import { getJobs, getUsers, mapJobsToUser } from "../../Auth/services/Authapi";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { formatDate } from "../../utils/Dateutils";
import { combineName } from '../../utils/NameUtils';
import { useClients } from "../../contexts/ClientsContext";

export const MapUser = () => {
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [filterClient, setFilterClient] = useState("");
  const user = useSelector((state) => state.auth.user);
  const clients = useClients();

  useEffect(() => {
    fetchJobs();
    fetchUsers();
    setTimeout(() => {
      console.log(clients, 'client list')
    }, 2000);
  }, []);

  const handleJobSelection = (jobId) => {
    setSelectedJobs((prevSelectedJobs) => {
      if (prevSelectedJobs.includes(jobId)) {
        return prevSelectedJobs.filter((id) => id !== jobId);
      } else {
        return [...prevSelectedJobs, jobId];
      }
    });
  };

  const handleUserChange = (e) => {
    setSelectedUser(e.target.value);
  };

  const handleFilterChange = (e) => {
    setFilterClient(e.target.value);
  };

  const filteredJobs = jobs.filter((job) =>
    job.clientName.toLowerCase().includes(filterClient.toLowerCase())
  );

  const handleMapJobs = async () => {
    if (selectedJobs.length > 0 && selectedUser) {
      // Create the payload based on selected jobs
      const payload = selectedJobs.map((jobId) => {
        const job = jobs.find((job) => job.jobId === jobId);
        return {
          jobId: job.jobId,
          jobTitle: job.jobTitle,
          jobTypeId: job.jobTypeId,
          clientId: job.clientId,
          orgId: job.orgId,
          userId: Number(selectedUser),
          jobDescription: job.jobDescription,
          workExperience: job.workExperience,
          noticePeriod: job.noticePeriod,
          minSalary: job.minSalary,
          maxSalary: job.maxSalary,
          jobStartDate: job.jobStartDate,
          jobEndDate: job.jobEndDate,
          locationIds: job.locationIds,
          jobComments: job.jobComments,
          isActive: job.isActive,
          jobSkills: job.jobSkills,
          jobStatus: job.jobStatus,
          jdUrl: job.jdUrl,
          clientName: job.clientName,
          userName: job.userName,
          jobLocations: job.jobLocations,
        };
      });

      try {
        // Send the payload to the API
        await mapJobsToUser(payload);
        // Reset selected jobs after mapping
        setSelectedJobs([]);
        setSelectedUser("");
        toast.success("Jobs successfully mapped to the user");
        await fetchJobs();
      } catch (error) {
        toast.error("An error occurred while mapping the jobs");
      }
    } else {
      toast.warn("Please select at least one job and a user to map");
    }
  };

  const fetchJobs = async () => {
    try {
      const jobsData = await getJobs(user.orgId);
      setJobs(Array.isArray(jobsData.data.data) ? jobsData.data.data : []);
    } catch (error) {
      toast.error("An error occurred while fetching the jobs");
    }
  };

  const fetchUsers = async () => {
    try {
      const usersData = await getUsers(user.orgId);
      setUsers(Array.isArray(usersData.data.data) ? usersData.data.data : []);
    } catch (error) {
      toast.error("An error occurred while fetching the users");
    }
  };

  return (
    <div className="m-5">
      <h2>Map User to Jobs</h2>

      <table className="table-auto w-full border-collapse">
        <thead>
          <tr className="table-header-bg">
            <th>Select</th>
            <th className="text-left py-3 px-4 uppercase font-semibold text-sm">
              Job Title
            </th>
            <th className="text-left py-3 px-4 uppercase font-semibold text-sm">
              Client Name
            </th>
            <th className="text-left py-3 px-4 uppercase font-semibold text-sm">
              Assigned User
            </th>
            <th className="text-left py-3 px-4 uppercase font-semibold text-sm">
              Start Date
            </th>
            <th className="text-left py-3 px-4 uppercase font-semibold text-sm">
              End Date
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredJobs.map((job) => (
            <tr key={job.jobId}>
              <td className="px-4 py-2 text-center">
                <input
                  type="checkbox"
                  checked={selectedJobs.includes(job.jobId)}
                  onChange={() => handleJobSelection(job.jobId)}
                />
              </td>
              <td className="text-left py-3 px-4">{job.jobTitle}</td>
              <td className="text-left py-3 px-4">{job.clientName}</td>
              <td className="text-left py-3 px-4">{job.userName}</td>
              <td className="text-left py-3 px-4">
                {formatDate(job.jobStartDate)}
              </td>
              <td className="text-left py-3 px-4">
                {formatDate(job.jobEndDate)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {selectedJobs.length > 0 && (
        <div className="mt-4 flex items-center justify-end">
          <div className="flex items-center mr-5 mt-4">
            <label htmlFor="userSelect" className="mr-2">
              Select User to Map Jobs:{" "}
            </label>
            <select
              id="userSelect"
              value={selectedUser}
              onChange={handleUserChange}
              className="p-2 border rounded w-48"
            >
              <option value="">Select a user</option>
              {users.map((user) => (
                <option key={user.userId} value={user.userId}>
                  {combineName(user.firstName, user.lastName)}
                </option>
              ))}
            </select>
          </div>

          <button
            type="button"
            className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
            onClick={handleMapJobs}
            disabled={selectedJobs.length === 0 || !selectedUser}
          >
            Map User
          </button>
        </div>
      )}
    </div>
  );
};

export default MapUser;
