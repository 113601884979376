import React from "react";

const Sales = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <h1 className="text-2xl font-bold text-gray-800">Sales coming soon...</h1>
    </div>
  );
};

export default Sales;
