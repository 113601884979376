import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SignupForm from "../components/SignupForm";
import LoginForm from "../components/LoginForm";
import "./SignupLoginPage.css";
import { useDispatch } from "react-redux";
import { LoginThunk, SignupThunk } from "../../store/reducers";
import { useNavigate } from "react-router-dom";

const SignupLoginPage = () => {
  const [activeTab, setActiveTab] = useState("login");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const switchTab = (tab) => {
    setActiveTab(tab);
  };

const handleSignup = async (userInfo) => {
  try {
    const action = await dispatch(SignupThunk(userInfo));

    if (action.payload) {
      const { statusCode, message } = action.payload;

      if (statusCode === 400) {
        const errorMessage = message || "An error occurred during signup.";
        toast.error(errorMessage);
        return;
      }

      if (statusCode === 200) {
        toast.success("Signup successful!");
        await setActiveTab("login");
      }
    } else {
      toast.error("An unexpected error occurred. Please try again.");
    }
  } catch (error) {
      toast.error("An error occurred during signup. Please try again.");
  }
};



const handleLogin = async (credentials) => {
  try {
    const action = await dispatch(LoginThunk(credentials));
    if (action?.payload?.data) {
      const { token } = action?.payload?.data; 
      localStorage.setItem('jwtToken', token);
      navigate("/landing-page/dashboard");
      toast.success("Login successful!");
    } else if (
      action?.payload?.data === null ||
      action?.payload?.data === undefined
    ) {
      toast.error("Invalid credentials, please try again.");
      return;
    } else {
      toast.error("Unexpected response from the server.");
      return;
    }
  } catch (error) {
    if (error.response) {
      toast.error(
        `Error: ${error.response.data.message || "Something went wrong!"}`
      );
    } else if (error.request) {
      toast.error("Network error: Unable to reach the server.");
    } else {
      toast.error("An unexpected error occurred. Please try again.");
    }
  }
};


  return (
    <div className="signin-page-wrapper">
      <div className="signup-login-container">
        <div className="tabs">
          <div
            onClick={() => switchTab("login")}
            className={`tab ${activeTab === "login" ? "active" : ""}`}
          >
            Login
          </div>
          <div
            onClick={() => switchTab("signup")}
            className={`tab ${activeTab === "signup" ? "active" : ""}`}
          >
            Signup
          </div>
        </div>
        <div>
          {activeTab === "login" ? (
            <LoginForm onSubmit={handleLogin} />
          ) : (
            <SignupForm onSubmit={handleSignup} />
          )}
        </div>
      </div>
    </div>
  );
};

export default SignupLoginPage;
