import React, { createContext, useContext, useState, useEffect } from "react";
import { getJobs } from "../Auth/services/Authapi";
import { useSelector } from "react-redux";

const JobsContext = createContext();

export const useJobs = () => useContext(JobsContext);

export const JobsProvider = ({ children }) => {
  const [jobs, setJobs] = useState([]);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        if (user && user.orgId) {
          const jobsData = await getJobs(user.orgId);
          setJobs(Array.isArray(jobsData.data.data) ? jobsData.data.data : []);
        }
      } catch (error) {
        console.error("Error fetching jobs:", error);
      }
    };

    if (user && user.orgId) {
      fetchJobs();
    }
  }, [user]);

  return <JobsContext.Provider value={jobs}>{children}</JobsContext.Provider>;
};
