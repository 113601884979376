// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Modal backdrop */
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
    z-index: 50; /* Ensure it's above other content */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Modal content */
  .modal-content {
    background-color: #ffffff; /* White background */
    border-radius: 0.5rem; /* Rounded corners */
    padding: 1.5rem; /* Padding inside the modal */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Shadow effect */
    max-width: 90%; /* Limit maximum width */
  }
  
  /* Close button */
  .close-btn {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background-color: transparent;
    border: none;
    padding: 0.5rem;
    cursor: pointer;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Modal.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC,EAAE,2BAA2B;IACjE,WAAW,EAAE,oCAAoC;IACjD,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA,kBAAkB;EAClB;IACE,yBAAyB,EAAE,qBAAqB;IAChD,qBAAqB,EAAE,oBAAoB;IAC3C,eAAe,EAAE,6BAA6B;IAC9C,uCAAuC,EAAE,kBAAkB;IAC3D,cAAc,EAAE,wBAAwB;EAC1C;;EAEA,iBAAiB;EACjB;IACE,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,6BAA6B;IAC7B,YAAY;IACZ,eAAe;IACf,eAAe;EACjB","sourcesContent":["/* Modal backdrop */\n.modal-backdrop {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */\n    z-index: 50; /* Ensure it's above other content */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  \n  /* Modal content */\n  .modal-content {\n    background-color: #ffffff; /* White background */\n    border-radius: 0.5rem; /* Rounded corners */\n    padding: 1.5rem; /* Padding inside the modal */\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Shadow effect */\n    max-width: 90%; /* Limit maximum width */\n  }\n  \n  /* Close button */\n  .close-btn {\n    position: absolute;\n    top: 0.5rem;\n    right: 0.5rem;\n    background-color: transparent;\n    border: none;\n    padding: 0.5rem;\n    cursor: pointer;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
