import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./LoginForm.css";
import { Link } from "react-router-dom";

const LoginForm = ({ onSubmit }) => {
  const form = useForm();
  const { register, handleSubmit, formState } = form;
  const { errors } = formState;

  const handleFormSubmit = async (data) => {
    try {
      await onSubmit(data);
    } catch (error) {
      toast.error("An error occurred during login. Please try again.");
      console.error("Login error:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
      <div className="login-form-grid">
        <div className="form-field">
          <label htmlFor="email" className="block text-gray-700">
            Email <span className="text-red-500">*</span>
          </label>
          <input
            type="email"
            id="email"
            className="w-full border border-gray-300 rounded-md"
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                message: "Invalid email format",
              },
            })}
          />
          <p className="error">{errors.email?.message}</p>
        </div>
        <div className="form-field">
          <label htmlFor="password" className="block text-gray-700">
            Password <span className="text-red-500">*</span>
          </label>
          <input
            type="password"
            id="password"
            className="w-full border border-gray-300 rounded-md"
            {...register("password", {
              required: "Password is required",
            })}
          />
          <p className="error">{errors.password?.message}</p>
        </div>
      </div>
      <button type="submit" className="gradient-button mt-4">
        Login
      </button>
      <div
        className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mt-5 mb-5"
        role="alert"
      >
        <p>
          Login successful! Please reach out to the admin to activate your
          account.
        </p>
      </div>
      <div className="mt-4">
        <Link to="/forgot-password" className="text-blue-500 hover:underline">
          Forgot Password?
        </Link>
      </div>
    </form>
  );
};

export default LoginForm;
