import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "./ForgotPassword.css";
import { useNavigate } from "react-router-dom";
import { sendOtp, validateOtp, resetPassword } from "../Auth/services/Authapi";

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [otpValidated, setOtpValidated] = useState(false);
  const navigate = useNavigate();

  const onSubmitEmail = async (data) => {
    try {
      const otpDetails = await sendOtp({ email: data.email });
      setEmail(data.email);
      localStorage.setItem("otpDetails", JSON.stringify(otpDetails));
      setStep(2);
      toast.success("OTP sent. Please check your email.");
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      console.error("Forgot password error:", error);
    }
  };

  const onValidateOtp = async (data) => {
    try {
      const validationData = {
        email: email,
        otp: data.otp,
      };
      const isValid = await validateOtp(validationData);

      if (isValid.data) {
        setOtpValidated(true);
        setStep(3);
        toast.success("OTP validated successfully.");
      } else {
        toast.error("Invalid OTP. Please try again.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      console.error("OTP validation error:", error);
    }
  };

  const onResetPassword = async (data) => {
    try {
      const otpDetails = JSON.parse(localStorage.getItem("otpDetails"));
      const resetData = {
        email: email,
        password: data.newPassword,
        userId: otpDetails.userId || 2,
      };
      const { data } = await resetPassword(resetData);
      if (data) {
        toast.success(
          "Password reset successful. Please log in with your new password."
        );
        navigate("/login");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      console.error("Password reset error:", error);
    }
  };

  return (
    <div className="forgot-password-page">
      <div className="forgot-password-container">
        {step === 1 && (
          <>
            <h2 className="title">Forgot Password</h2>
            <p className="description">
              Enter your email address below and we'll send you an OTP.
            </p>
            <form
              onSubmit={handleSubmit(onSubmitEmail)}
              className="forgot-password-form"
            >
              <div className="form-field">
                <label htmlFor="email" className="label">
                  Email <span className="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  className="input"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: "Invalid email format",
                    },
                  })}
                />
                {errors.email && (
                  <p className="error-message">{errors.email.message}</p>
                )}
              </div>
              <button type="submit" className="gradient-button">
                Send OTP
              </button>
            </form>
          </>
        )}

        {step === 2 && (
          <>
            <h2 className="title">Validate OTP</h2>
            <p className="description">
              We've sent an OTP to your email: {email}. Please enter it below to
              validate.
            </p>
            <form
              onSubmit={handleSubmit(onValidateOtp)}
              className="forgot-password-form"
            >
              <div className="form-field">
                <label htmlFor="otp" className="label">
                  OTP <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="otp"
                  className="input"
                  {...register("otp", {
                    required: "OTP is required",
                    minLength: {
                      value: 6,
                      message: "OTP must be 6 digits",
                    },
                  })}
                />
                {errors.otp && (
                  <p className="error-message">{errors.otp.message}</p>
                )}
              </div>
              <button type="submit" className="gradient-button">
                Validate OTP
              </button>
            </form>
          </>
        )}

        {step === 3 && otpValidated && (
          <>
            <h2 className="title">Reset Password</h2>
            <p className="description">Enter your new password below.</p>
            <form
              onSubmit={handleSubmit(onResetPassword)}
              className="forgot-password-form"
            >
              <div className="form-field">
                <label htmlFor="newPassword" className="label">
                  New Password <span className="text-red-500">*</span>
                </label>
                <input
                  type="password"
                  id="newPassword"
                  className="input"
                  {...register("newPassword", {
                    required: "New password is required",
                    minLength: {
                      value: 8,
                      message: "Password must be at least 8 characters",
                    },
                  })}
                />
                {errors.newPassword && (
                  <p className="error-message">{errors.newPassword.message}</p>
                )}
              </div>
              <button type="submit" className="gradient-button">
                Reset Password
              </button>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
