import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { getCandidatesByClientId } from "../../Auth/services/Authapi";
import { useSelector } from "react-redux";
import { getCities } from "../../Auth/services/Authapi";

const ViewCandidates = () => {
  const [candidates, setCandidates] = useState([]);
  const { ids } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {

    const fetchCandidates = async () => {
      try {

        // Get query parameters
        const queryParams = new URLSearchParams(location.search);
        let orgId = user.orgId;
        let clientId = queryParams.get("clientId");
        let jobId = queryParams.get("jobId");

        // Hard-code clientId and jobId if not provided
        if (!clientId || !jobId) {
          clientId = clientId || 2;
          jobId = jobId || 1;

          // Update the URL with the default values
          const newUrl = `${location.pathname}?orgId=${orgId}&clientId=${clientId}&jobId=${jobId}`;
          navigate(newUrl, { replace: true });
        }

        // Fetch candidates by client ID
        const payload = {
          orgId,
          clientId,
          jobId,
        };
        console.log('payload', payload);
        const response = await getCandidatesByClientId(payload);
        setCandidates(response.data.data);
      } catch (error) {
        console.error("Error fetching candidates:", error);
      }
    };

    fetchCandidates();
  }, [ids, location.search, location.pathname, navigate]);

return (
  <div className="m-2">
    <h1 className="text-xl font-bold mb-4">Selected Candidates</h1>
    <table className="table-auto w-full border-collapse mt-5">
      <thead>
        <tr className="bg-gray-200">
          <th className="px-4 py-2 border">Name</th>
          <th className="px-4 py-2 border">Email</th>
          <th className="px-4 py-2 border">Phone</th>
          <th className="px-4 py-2 border">Notice Period</th>
          <th className="px-4 py-2 border">Current CTC</th>
          <th className="px-4 py-2 border">Resume</th>
        </tr>
      </thead>
      <tbody>
        {candidates.length === 0 ? (
          <tr>
            <td colSpan="6" className="text-center py-4">
              No candidates available
            </td>
          </tr>
        ) : (
          candidates.map((candidate) => (
            <tr key={candidate.candidateId} className="border-b">
              <td className="px-4 py-2 text-center">
                {candidate.candidateFullName}
              </td>
              <td className="px-4 py-2 text-center">{candidate.emailId}</td>
              <td className="px-4 py-2 text-center">{candidate.mobileNo}</td>
              <td className="px-4 py-2 text-center">
                {candidate.noticePeriod}
              </td>
              <td className="px-4 py-2 text-center">{candidate.currentCtc}</td>
              <td className="px-4 py-2 text-center">
                <a
                  href={candidate.resumeUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline"
                >
                  View Resume
                </a>
              </td>
            </tr>
          ))
        )}
      </tbody>
    </table>
  </div>
);
};

export default ViewCandidates;
