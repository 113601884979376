import React from 'react';
import { ClientsProvider } from './ClientsContext';
import { JobsProvider } from './JobsContext';
import { AuthProvider } from './AuthContext';


const AppProvider = ({children}) => {
      return (
        <AuthProvider>
          <ClientsProvider>
            <JobsProvider>{children}</JobsProvider>
          </ClientsProvider>
        </AuthProvider>
      );
}

export default AppProvider;
