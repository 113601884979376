
import React from "react";
import Modal from "../../src/components/Modal";

const ConfirmationModal = ({ isOpen, onClose, onConfirm, message }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={<h2 className="text-lg font-semibold">Are you sure?</h2>}
      footer={
        <div className="flex justify-center mt-6">
          <button
            onClick={onClose}
            className="text-gray-700 border border-gray-800 px-4 py-1 rounded hover:bg-gray-300 focus:outline-none focus:bg-gray-300 mr-2"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              onConfirm();
              onClose();
            }}
            className="text-white bg-red-600 px-4 py-1 rounded hover:bg-red-700 focus:outline-none focus:bg-red-700"
          >
            Confirm
          </button>
        </div>
      }
    >
      <p>{message}</p>
    </Modal>
  );
};

export default ConfirmationModal;
