import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  getOrganizationTypes,
} from "../../Auth/services/Authapi";
import { toast } from "react-toastify";
import "./SignupForm.css";

const SignupForm = ({ onSubmit }) => {
  const { register, control, handleSubmit, watch, formState, reset, setValue } =
    useForm();
  const { errors } = formState;
  const [orgTypes, setOrgTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Handle form submission
  const handleFormSubmit = async (data) => {
    try {
      const payload = transformDataToPayload(data);
      await onSubmit(payload);
      reset();
    } catch (error) {
      toast.error("An error occurred during signup. Please try again.");
      console.error("Signup error:", error);
    }
  };

  // Watching values for dynamic field updates
  const orgType = parseInt(watch("orgTypeId"));
  const firstName = watch("firstName");
  const lastName = watch("lastName");

  // Fetch organization types on mount
  useEffect(() => {
    getOrganizationTypes()
      .then((res) => {
        setOrgTypes(res.data.data);
      })
      .catch(setError)
      .finally(() => setLoading(false));
  }, []);

  // Automatically patch orgName when orgType is "freelancer"
  useEffect(() => {
    if (orgType === 1) {
      setValue("name", `${firstName || ""} ${'Org'}`);
    }
  }, [orgType, firstName, lastName, setValue]);

  // Transform data to payload format
  const transformDataToPayload = (data) => {
    const {
      orgId,
      name,
      address,
      city,
      state,
      pincode,
      orgTypeId,
      ...userInfo
    } = data;
    return {
      orgTypeId: Number(orgTypeId),
      name,
      address,
      city,
      state,
      pincode,
      userInfo: {
        ...userInfo,
        middleName: userInfo.middleName || "",
        phoneNumber2: userInfo.phoneNumber2 || "",
        isAdmin: true,
      },
    };
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
      <div className="signup-form-grid">
        {/* First Name Field */}
        <div className="form-field">
          <label htmlFor="firstName">
            First Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="firstName"
            {...register("firstName", {
              required: "First name is required",
              pattern: {
                value: /^[A-Za-z\s]+$/,
                message: "First name can only contain alphabets",
              },
              maxLength: {
                value: 20,
                message: "First name cannot exceed 20 characters",
              },
            })}
            className="w-full border border-gray-300 rounded-md"
          />
          <p className="error">{errors.firstName?.message}</p>
        </div>

        {/* Last Name Field */}
        <div className="form-field">
          <label htmlFor="lastName">
            Last Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="lastName"
            {...register("lastName", {
              required: "Last name is required",
              pattern: {
                value: /^[A-Za-z\s]+$/,
                message: "Last name can only contain alphabets",
              },
              maxLength: {
                value: 20,
                message: "Last name cannot exceed 20 characters",
              },
            })}
            className="w-full border border-gray-300 rounded-md"
          />
          <p className="error">{errors.lastName?.message}</p>
        </div>

        {/* Email Field */}
        <div className="form-field">
          <label htmlFor="email">
            Email <span className="text-red-500">*</span>
          </label>
          <input
            type="email"
            id="email"
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                message: "Invalid email format",
              },
            })}
            className="w-full border border-gray-300 rounded-md"
          />
          <p className="error">{errors.email?.message}</p>
        </div>

        {/* Password Field */}
        <div className="form-field">
          <label htmlFor="password">
            Password <span className="text-red-500">*</span>
          </label>
          <input
            type="password"
            id="password"
            {...register("password", {
              required: "Password is required",
              maxLength: {
                value: 20,
                message: "Password cannot exceed 20 characters",
              },
            })}
            className="w-full border border-gray-300 rounded-md"
          />
          <p className="error">{errors.password?.message}</p>
        </div>

        {/* Organization Type Field */}
        <div className="form-field">
          <label htmlFor="orgTypeId">
            Organization Type <span className="text-red-500">*</span>
          </label>
          <select
            id="orgTypeId"
            {...register("orgTypeId", {
              required: "Organization Type ID is required",
            })}
            defaultValue=""
            className="w-full border border-gray-300 rounded-md"
          >
            <option value="" disabled>
              Select an option
            </option>
            {orgTypes.map((option) => (
              <option key={option.orgTypeId} value={option.orgTypeId}>
                {option.orgType}
              </option>
            ))}
          </select>
          <p className="error">{errors.orgTypeId?.message}</p>
        </div>

        {/* Organisation Name Field */}
        <div className="form-field">
          <label htmlFor="name">
            Organisation Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="name"
            {...register("name", {
              required: "Organisation name is required",
              pattern: {
                value: /^[A-Za-z\s]+$/,
                message: "Organisation name can only contain alphabets",
              },
              maxLength: {
                value: 20,
                message: "Organisation name cannot exceed 20 characters",
              },
            })}
            className="w-full border border-gray-300 rounded-md"
          />
          <p className="error">{errors.name?.message}</p>
        </div>

        {/* Contact Number Field */}
        <div className="form-field full-width">
          <label htmlFor="phoneNumber1">
            Contact Number <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="phoneNumber1"
            {...register("phoneNumber1", {
              required: "Phone number is required",
              pattern: {
                value: /^\d{10}$/,
                message: "Phone number must be 10 digits",
              },
              maxLength: {
                value: 10,
                message: "Phone number cannot exceed 10 digits",
              },
            })}
            className="w-full border border-gray-300 rounded-md"
          />
          <p className="error">{errors.phoneNumber1?.message}</p>
        </div>
      </div>

      {/* Submit Button */}
      <button type="submit" className="gradient-button mt-4">
        Signup
      </button>
    </form>
  );
};

export default SignupForm;
