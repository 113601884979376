
export const jobTypes = [
  { id: 1, label: "Full Time" },
  { id: 2, label: "Part Time" },
  { id: 3, label: "Internship" },
];

export const noticePeriodOptions = [
  { value: 0, label: "Immediate" },
  { value: 15, label: "15 Days" },
  { value: 30, label: "30 Days" },
  { value: 45, label: "45 Days" },
  { value: 60, label: "60 Days" },
  { value: 90, label: "90 Days" },
];

export const getJobTypeLabel = (id) => {
  const jobType = jobTypes.find((type) => type.id === id);
  return jobType ? jobType.label : "";
};

export const getJobTypeId = (label) => {
  const jobType = jobTypes.find((type) => type.label === label);
  return jobType ? jobType.id : null;
};