import React, { useEffect, useState } from "react";
import "../components/Modal.css";
import { AiOutlineClose } from "react-icons/ai";

const Modal = ({ isOpen, onClose, children, header, footer }) => {
  const [isVisible, setIsVisible] = useState(isOpen);

  useEffect(() => {
    setIsVisible(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    setIsVisible(false);
    if (onClose) onClose(); // Only call onClose if it's provided
  };

  return (
    <>
      {isVisible && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div
            className="bg-white rounded-lg p-8 flex flex-col relative"
            style={{
              width: "80%",
              maxWidth: "800px",
              maxHeight: "80%",
              overflowY: "auto",
            }}
          >
            {/* Close Icon */}
            <AiOutlineClose
              onClick={handleClose}
              className="absolute top-4 right-8 text-gray-500 hover:text-gray-800 cursor-pointer"
              size={20}
              aria-label="Close Modal"
            />

            {header && (
              <div className="mb-4 text-2xl font-semibold">{header}</div>
            )}
            <div>{children}</div>
            {footer && <div className="mt-6">{footer}</div>}
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
