import React from "react";
import { BrowserRouter, useRoutes } from "react-router-dom";
import { appRoutes } from "./Router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './App.css';


const AppRoutes = () => {
  return useRoutes(appRoutes);
};

function App() {
  return (
    <BrowserRouter>
      <AppRoutes />
      <ToastContainer
        position="top-right"
        className="flex"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
      />
    </BrowserRouter>
  );
}

export default App;
